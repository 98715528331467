var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "face", attrs: { id: "app" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showContainer,
            expression: "showContainer"
          }
        ],
        staticClass: "face-capture",
        attrs: { id: "face-capture" }
      },
      [
        _c("p", { staticClass: "tip" }, [_vm._v("请保持人像在取景框内")]),
        _c("video", {
          attrs: {
            id: "video",
            width: _vm.vwidth,
            height: _vm.vheight,
            playsinline: "",
            "webkit-playsinline": ""
          }
        }),
        _c("canvas", {
          attrs: { id: "refCanvas", width: _vm.cwidth, height: _vm.cheight }
        })
      ]
    ),
    !_vm.showContainer
      ? _c("div", { staticClass: "img-face" }, [
          _c("img", {
            staticClass: "imgurl",
            attrs: { width: _vm.vwidth, height: _vm.vheight, src: _vm.imgUrl }
          })
        ])
      : _vm._e(),
    _c("h3", { staticClass: "contentp" }, [_vm._v(_vm._s(_vm.scanTip))]),
    _vm.loading
      ? _c("div", { staticClass: "loadingBox" }, [_vm._m(0)])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("div", { staticClass: "loadText" }, [_vm._v("识别中...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }